$(document).ready(function () {
	//Declare normal variable javascript
	//Hide element when smaller than 1025
	if ($(window).width() < 1025) {
		$(".bottom-header").fadeIn(function () {
			$(".bottom-header").css({
				display: "flex"
			});
		});
	}
	//Image map tool
	//Map high light
	$('.image-map-wrapper img[usemap]').maphilight();
	$('.image-map-wrapper img[usemap]').rwdImageMaps();

	//map hight light text
	$('.tooltip').tooltipster({
		contentCloning: true,
		maxWidth: 320,
		minWidth: 280,

	});
	// Map responsive






	//Toggle Search
	$(".search-toggle").on("click", function () {
		$(".searchbox").toggleClass("active");
		event.preventDefault();
		$(".searchbox").addClass("open");
		$('.searchbox  > input[type="text"]').focus();
	});

	$(".searchbox, .searchbox button.close").on("click keyup", function (event) {
		if (
			event.target == this ||
			event.target.className == "close" ||
			event.keyCode == 27
		) {
			$(this).removeClass("open");
		}
	});



	//Declare function Javascript
	tabActive();
	swiperInit();
	backToTop();
	mappingHTML();
	mappingSocial();
	toggleMobileMenu();
	toggleApplyForm();
	jsSocialShare();
	removeModal();

	if ($(window).width() > 1024) {
		const $menu = $(".searchbox");
		$(document).mouseup(e => {
			if (!$menu.is(e.target) && // if the target of the click isn't the container...
				$menu.has(e.target).length === 0
			) {
				// ... nor a descendant of the container
				$menu.removeClass("active");
			}
		});
	}
	$(".about-us-navigation a").on('click', function (event) {

		// Make sure this.hash has a value before overriding default behavior
		if (this.hash !== "") {
			// Prevent default anchor click behavior
			let offset = 150;
			// Store hash
			var hash = this.hash;

			// Using jQuery's animate() method to add smooth page scroll
			// The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
			$('html, body').animate({
				scrollTop: $(hash).offset().top - offset
			}, 800, function () {

				// Add hash (#) to URL when done scrolling (default click behavior)
				window.location.hash = hash;
			});
		} // End if
	});
});




$(".home-banner .slide-chevron").on('click', function (event) {

	// Make sure this.hash has a value before overriding default behavior
	if (this.hash !== "") {
		// Prevent default anchor click behavior
		let offset = 50;
		// Store hash
		var hash = this.hash;

		// Using jQuery's animate() method to add smooth page scroll
		// The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
		$('html, body').animate({
			scrollTop: $(hash).offset().top - offset
		}, 800, function () {

			// Add hash (#) to URL when done scrolling (default click behavior)
			window.location.hash = hash;
		});
	} // End if
});

//Check if windows size large then 1024 then these function will be execute
if ($(window).width() > 1024) {
	const $menu = $(".searchbox");
	$(document).mouseup(e => {
		if (!$menu.is(e.target) && // if the target of the click isn't the container...
			$menu.has(e.target).length === 0
		) {
			// ... nor a descendant of the container
			$menu.removeClass("active");
		}
	});
}



function fixedTopNavigation() {
	if ($("body").hasClass("top-navigation-section")) {
		if ($(window).width() > 1025) {
			if (
				document.body.scrollTop > 470 ||
				document.documentElement.scrollTop > 470
			) {
				$(".top-navigation").addClass("minimize");
			} else {
				$(".top-navigation").removeClass("minimize");
			}

		} else if ($(window).width() < 1025) {
			if (
				document.body.scrollTop > 360 ||
				document.documentElement.scrollTop > 360
			) {
				$(".top-navigation").addClass("minimize");
			} else {
				$(".top-navigation").removeClass("minimize");
			}
		}
	} else if ($(window).width() < 768) {
		if (
			document.body.scrollTop > 370 ||
			document.documentElement.scrollTop > 370
		) {
			$(".top-navigation").addClass("minimize");
		} else {
			$(".top-navigation").removeClass("minimize");
		}
	} else if ($(window).width() < 576) {
		if (
			document.body.scrollTop > 280 ||
			document.documentElement.scrollTop > 280
		) {
			$(".top-navigation").addClass("minimize");
		} else {
			$(".top-navigation").removeClass("minimize");
		}
	}


}



function removeModal(){

	$('.remove-modal').on('click',function(){
		$('.modal-fixed-bottom').hide();
	})
}

function fixedTopNavigationITPark() {
	if ($("body").hasClass("top-navigation-section-it-park")) {
		if (
			document.body.scrollTop > 100 ||
			document.documentElement.scrollTop > 100
		) {
			$(".top-navigation").addClass("minimize");
		} else {
			$(".top-navigation").removeClass("minimize");
		}


	}
}


	// Back to top
	function backToTop() {
		let btn = $(".back-to-top");
		btn.on("click", function (e) {
			e.preventDefault();
			$("html, body").animate({
					scrollTop: 0
				},
				"300"
			);
		});
	}
	// Remove when click outside the circle

	function tabActive() {
		$(".tab-navigation li a").on("click", function () {
			$(this)
				.parents(".tab-navigation")
				.find("li")
				.removeClass("active");
			$(this)
				.parents("li")
				.addClass("active");

			var display = $(this).attr("data-type");
			$(".tab-item").removeClass("active");
			$("#" + display).addClass("active");
		});
	}

	function toggleApplyForm() {
		$(".apply-form .button-apply a").on("click", function () {
			$(".form-apply").slideToggle();
		});
	}

	function swiperInit() {
		var homerSwiper = new Swiper(".home-banner .swiper-container", {
			// Optional parameters
			speed: 1205,
			slidesPerView: 1,
			autoplay: {
				delay: 3000
			},
			navigation: {
				nextEl: ".swiper-next-home",
				prevEl: ".swiper-prev-home"
			}
		});

		var topBanner = new Swiper(".top-banner .swiper-container", {
			// Optional parameters
			speed: 1205,
			slidesPerView: 1,
			autoplay: {
				delay: 3000
			}
		});
		var topBanner = new Swiper(".banner-fluid-caption-top .swiper-container", {
			// Optional parameters
			speed: 1205,
			slidesPerView: 1,
			autoplay: {
				delay: 3000
			}
		});
		var homeNews = new Swiper(".swiper-quad .swiper-container", {
			// Optional parameters
			speed: 1205,
			slidesPerView: 5,
			autoplay: {
				delay: 3000
			},
			breakpointsInverse: true,
			breakpoints: {
				320: {
					slidesPerView: 1
				},
				576: {
					slidesPerView: 2
				},
				769: {
					slidesPerView: 3
				},
				1025: {
					slidesPerView: 4
				},
				1280: {
					slidesPerView: 5
				}
			},
			spaceBetween: 10,
			navigation: {
				nextEl: ".swiper-next",
				prevEl: ".swiper-prev"
			}
		});
		var homeNews = new Swiper(".news-fluid .swiper-container", {
			// Optional parameters
			speed: 1205,
			slidesPerView: 5,
			autoplay: {
				delay: 3000
			},
			spaceBetween: 30,
			breakpointsInverse: true,
			breakpoints: {
				320: {
					slidesPerView: 1
				},
				576: {
					slidesPerView: 2
				},
				769: {
					slidesPerView: 3
				},
				1025: {
					slidesPerView: 4
				},
				1280: {
					slidesPerView: 4.5
				}
			},
			navigation: {
				nextEl: ".swiper-navigation-black .swiper-next",
				prevEl: ".swiper-navigation-black .swiper-prev"
			}
		});

		var homeNews = new Swiper(".swiper-center .swiper-container", {
			// Optional parameters
			speed: 1205,
			autoplay: {
				delay: 3000
			},
			breakpointsInverse: true,
			breakpoints: {
				320: {
					slidesPerView: 1
				},
				576: {
					slidesPerView: 1.5
				},
				769: {
					slidesPerView: 1.5
				},
				1025: {
					slidesPerView: 1.5
				}
			},

			spaceBetween: 30,
			centeredSlides: true,
			navigation: {
				nextEl: ".arrow-next",
				prevEl: ".arrow-prev"
			}
		});
		var homePartner = new Swiper(".partner-swiper .swiper-container", {
			// Optional parameters
			speed: 1205,
			spaceBetween: 0,
			autoplay: {
				delay: 3000
			},
			navigation: {
				nextEl: ".swiper-navigation-grey-small .swiper-next",
				prevEl: ".swiper-navigation-grey-small .swiper-prev"
			},
			breakpointsInverse: true,
			breakpoints: {
				320: {
					slidesPerView: 1
				},
				350: {
					slidesPerView: 2
				},
				576: {
					slidesPerView: 3
				},
				768: {
					slidesPerView: 4
				},
				1025: {
					slidesPerView: 6
				}
			}
		});


		var GalleryTop = new Swiper(".swiper-gallery-compact-wrapper .swiper-gallery-compact-top", {
			// Optional parameters
			speed: 1205,
			spaceBetween: 0,
			autoplay: {
				delay: 3000
			},
			navigation: {
				nextEl: ".swiper-navigation-white .swiper-next",
				prevEl: ".swiper-navigation-white .swiper-prev"
			},
			breakpointsInverse: true,
			breakpoints: {
				320: {
					slidesPerView: 1
				},
				350: {
					slidesPerView: 1
				},
				576: {
					slidesPerView: 2
				},
				768: {
					slidesPerView: 2
				},
				1025: {
					slidesPerView: 3
				}
			}
		});

		var GalleryBottom = new Swiper(".swiper-gallery-compact-wrapper .swiper-gallery-compact-bottom", {
			// Optional parameters
			speed: 1205,
			spaceBetween: 0,
			autoplay: {
				delay: 3000
			},
			navigation: {
				nextEl: ".swiper-navigation-white .swiper-next",
				prevEl: ".swiper-navigation-white .swiper-prev"
			},
			breakpointsInverse: true,
			breakpoints: {
				320: {
					slidesPerView: 1
				},
				350: {
					slidesPerView: 2
				},
				576: {
					slidesPerView: 2
				},
				768: {
					slidesPerView: 3
				},
				1025: {
					slidesPerView: 4
				}
			}
		});


	}

	function toggleMobileMenu() {
		$(".mobile-toggle").on("click", function () {
			$(".mobile-wrapper").toggleClass("active");
		});
	}

	function mappingHTML() {
		return new MappingListener({
			selector: ".main-nav",
			mobileWrapper: ".mobile-wrapper",
			mobileMethod: "appendTo",
			desktopWrapper: ".header-nav-right",
			desktopMethod: "prependTo",
			breakpoint: 1025
		}).watch();
	}

	function mappingSocial() {
		return new MappingListener({
			selector: ".social-wrapper",
			mobileWrapper: ".mobile-wrapper",
			mobileMethod: "appendTo",
			desktopWrapper: ".mobile-toggle",
			desktopMethod: "insertBefore",
			breakpoint: 1025
		}).watch();
	}

	$(document).ready(function () {
		jQuery(function ($) {
			"use strict";

			var counterUp = window.counterUp["default"]; // import counterUp from "counterup2"

			var $counters = $(".counter");

			/* Start counting, do this on DOM ready or with Waypoints. */
			$counters.each(function (ignore, counter) {
				var waypoint = new Waypoint({
					element: $(this),
					handler: function () {
						counterUp(counter, {
							duration: 5000,
							delay: 16
						});
						this.destroy();
					},
					offset: "bottom-in-view"
				});
			});
		});
	});


	function jsSocialShare() {
		$("#shareNative").jsSocials({
			showLabel: false,
			showCount: false,
			shareIn: "popup",
			shares: [{
					share: "twitter",
					logo: "fab fa-twitter"
				},
				{
					share: "facebook",
					logo: "fab fa-facebook-f"
				},
				{
					share: "linkedin",
					logo: "fab fa-linkedin-in"
				}

			]
		});
	}
	window.onscroll = function () {
		fixedTopNavigation();
		fixedTopNavigationITPark();
	};